@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-container {
  position: relative;
  width: 100%;
}

.animate-marquee {
  display: inline-block;
  animation: marquee 30s linear infinite;
}

.font-hanalei {
  font-family: 'Hanalei Fill', cursive;
}

/* 自定义日历样式 */
.react-calendar {
  width: 100%;
  background: transparent;
  border: none;
  font-family: 'Hanalei Fill', cursive;
  padding: 20px;
}

.react-calendar__navigation {
  margin-bottom: 30px;
}

.react-calendar__navigation button {
  font-size: 1.5rem;
  padding: 0.5rem;
  background: none;
  border: none;
  font-family: 'Hanalei Fill', cursive;
}

.react-calendar__month-view__weekdays {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 1rem;
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-family: 'Hanalei Fill', cursive;
}

.react-calendar__tile {
  padding: 1.5rem;
  font-size: 1.1rem;
  background: none;
  border: none;
  font-family: 'Hanalei Fill', cursive;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f3f4f6;
  border-radius: 8px;
}

.react-calendar__tile--active {
  background: black !important;
  color: white;
  border-radius: 8px;
}

.react-calendar__tile--now {
  background: #e5e7eb;
  border-radius: 8px;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f3f4f6;
  border-radius: 8px;
}

/* 自定义滚动条样式 */
.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* 自定义日期范围选择器样式 */
.custom-daterange .rdrMonth {
  font-family: 'Hanalei Fill', cursive;
}

.custom-daterange .rdrDayNumber {
  font-family: 'Hanalei Fill', cursive;
}

.custom-daterange .rdrMonthAndYearPickers select {
  font-family: 'Hanalei Fill', cursive;
}

.custom-daterange .rdrWeekDay {
  font-family: 'Hanalei Fill', cursive;
}

.custom-daterange .rdrDateDisplayWrapper {
  background-color: white;
}

.custom-daterange .rdrDateDisplay {
  margin: 0.5rem;
}

.custom-daterange .rdrDateDisplayItem {
  border-radius: 0.5rem;
  background-color: #f3f4f6;
  border: 2px solid black;
}

.custom-daterange .rdrDateDisplayItem input {
  font-family: 'Hanalei Fill', cursive;
}

/* 自定义日历样式 */
.custom-calendar .react-calendar {
  width: 100%;
  background: transparent;
  border: none;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  padding: 0.1em;
}

.custom-calendar .react-calendar__navigation {
  margin-bottom: 3px;
}

.custom-calendar .react-calendar__navigation button {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-transform: uppercase;
  color: #666;
}

.custom-calendar .react-calendar__month-view__weekdays__weekday {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-transform: uppercase;
  color: #666;
}

.custom-calendar .react-calendar__tile {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  padding: 0.4em 0.3em;
  border-radius: 8px;
}

.custom-calendar .react-calendar__tile:enabled:hover,
.custom-calendar .react-calendar__tile:enabled:focus {
  background-color: #f3f4f6;
}

.custom-calendar .react-calendar__tile--active {
  background: black !important;
  color: white;
}

.custom-calendar .react-calendar__tile--now {
  background: #e5e7eb;
}

/* 紧凑型日历样式 */
.calendar-compact.react-calendar {
  max-width: 300px;
  margin: 0 auto;
}

.calendar-compact .react-calendar__tile {
  padding: 0.3em 0.2em;
  font-size: 0.9em;
}

.calendar-compact .react-calendar__navigation {
  height: 30px;
  margin-bottom: 0.1em;
}

.calendar-compact .react-calendar__navigation button {
  font-size: 0.9em;
  padding: 0.15em;
}

.calendar-compact .react-calendar__month-view__weekdays {
  font-size: 0.75em;
  margin-bottom: 0.1em;
}

.calendar-compact .react-calendar__month-view__days {
  gap: 2px;
}

.calendar-compact .react-calendar__tile--active {
  background: black !important;
  color: white;
}
